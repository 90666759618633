import * as React from "react";
import styled from "styled-components";

import PageLayout from "./page-layout";
import PageWrapper from "../components/page-wrapper";

const Wrapper = styled(PageWrapper)`
  padding-top: 80px;
  max-width: 640px;
`;

const DocsLayout = ({ children }) => {
  return (
    <PageLayout>
      <Wrapper>{children}</Wrapper>
    </PageLayout>
  );
};

export default DocsLayout;
